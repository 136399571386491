/* Main Layout */
.main {
  width: 90%;
  padding: 20px 10px;
  margin: 4rem auto 0;
}

.greet-main {
  width: 90%;
  padding: 20px 10px;
  margin: 2rem auto 0;
}

.greeting-main {
  display: flex;
}

.greeting-main > * {
  flex: 1;
  margin-bottom: 30px;
}

/* Text Styles */
.greeting-text {
  margin-top: 80px;
  font-size: 70px;
  line-height: 1.1;
  font-family: "Google Sans Bold";
}

.greeting-nickname {
  font-size: 40px;
  line-height: 0;
  font-family: "Agustina Regular";
}

.greeting-text-p {
  font-size: 40px;
  line-height: 40px;
  margin-right: 40px;
  font-family: "Google Sans Medium";
}

.greeting-text-message {
  font-size: 12px;
  line-height: 20px;
  font-family: "Google Sans Medium";
  text-align: justify;
}

/* Button and Image */
.button-greeting-div {
  display: flex;
  margin-top: 20px;
}

.greeting-image-div {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.greeting-image-div > * {
  max-width: 100%;
  height: auto;
}

/* Strengths Section */
.strengths {
  margin: 2rem auto;
  width: 90%;
  max-width: 1200px;
}

.strength-tiles {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.25rem;
  margin: 0 auto;
}

.strength-tile {
  position: relative;
  padding: 1.25rem;
  border-radius: 12px;
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
  background: rgba(0, 255, 0, 0.03);
  border: 1px solid rgba(0, 255, 0, 0.2);
  box-shadow: 0 0 20px rgba(0, 255, 0, 0.1);
}

/* SVG Border Animation */
.border-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 2;
}

.border-line {
  fill: none;
  stroke: url(#border-gradient);
  stroke-width: 2;
}

/* Strength Tile Content */
.strength-icon {
  margin-bottom: 0.75rem;
  display: inline-flex;
  padding: 0.5rem;
  border-radius: 8px;
  background: rgba(0, 255, 0, 0.1);
  position: relative;
  z-index: 3;
}

.strength-title {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  font-family: "Google Sans Medium";
  position: relative;
  z-index: 3;
}

.strength-description {
  font-size: 0.85rem;
  line-height: 1.4;
  opacity: 0.9;
  font-family: "Google Sans Regular";
  position: relative;
  z-index: 3;
}

/* Media Queries */
@media (max-width: 1380px) {
  .greeting-text {
    font-size: 50px;
  }
  .greeting-text-p {
    font-size: 20px;
  }
  .greeting-nickname {
    font-size: 28px;
    line-height: 0;
    font-family: "Agustina Regular";
  }
}

@media (max-width: 1024px) {
  .strength-tiles {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .button-greeting-div {
    justify-content: space-around;
  }
  .greeting-text {
    font-size: 30px;
    margin-top: 0;
    text-align: center;
  }
  .greeting-nickname {
    font-size: 25px;
    text-align: center;
  }
  .greeting-text-p {
    font-size: 16px;
    margin-right: 0;
    line-height: normal;
    text-align: center;
  }
  .greeting-main {
    display: block;
  }
  .portfolio-repo-btn-div {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .strength-tiles {
    grid-template-columns: 1fr;
  }
  .strength-tile {
    padding: 1rem;
  }
}

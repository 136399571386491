.feeling-proud-container {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.logo_wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.screen {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

body {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.screen {
  /* background-color: #55198b; */
  overflow: hidden;
  border-radius: 50%;
  animation: spin 1s forwards;
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 2px solid #adff2f; */
  padding: 15px;
  box-shadow: 0 0 30px 15px #adff2f;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: spin 0.5s infinite linear; /* Adjust the duration as needed */
}
